import React, { useEffect, useRef, useState, useContext } from "react";
import "./index.css";
import GGLogo from "../../assets/Images/HomePage/GGLogo.png";
import { NavLink } from "react-router-dom";
import rightShiftIcon from "../../assets/Images/HomePage/rightAngularDouble.svg";
import { modalContext } from "../LayoutPage";

function Navbar() {
  const { toggleModal, setAnimateModal } = useContext(modalContext);
  const sideBarRef = useRef();
  const [showSideBar, setShowSideBar] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const navData = [
    { title: "Home", path: "/" },
    { title: "About us", path: "/aboutUs" },
    // { title: "Blog", path: "/blogs" },
    // { title: "Store", path: "/store" },
    // { title: "Partner with us", path: "/partnerWithUs" },
  ];

  const toggleSideBar = () => {
    if (showSideBar) {
      sideBarRef.current.className = "sideNavContainer";
    } else {
      sideBarRef.current.className = "sideNavContainer active";
    }
    setShowSideBar(!showSideBar);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className="navOuterContainer"
        style={{
          position: isSticky ? "sticky" : "absolute",
        }}
      >
        <div className="navLogoContainer">
          <NavLink to="/">
            <img src={GGLogo} alt="mainLogo" />
          </NavLink>
        </div>
        <div className="navItemContainer">
          <nav>
            <ul>
              {navData.map((item, index) => {
                return (
                  <li key={index.toString()}>
                    <NavLink
                      to={item.path}
                      style={({ isActive }) => ({
                        color: isActive ? "#000000" : "#999393",
                        textDecoration: "none",
                        borderBottom: isActive
                          ? "0.25rem solid #1A2D69"
                          : "none",
                        fontWeight: "800",
                        fontFamily: "Avenir Regular",
                        lineHeight: "2.9rem",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        letterSpacing: "1px",
                      })}
                      onClick={() => {
                        window.scrollTo(0);
                      }}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
        <div className="navDownload">
          <h4
            onClick={() => {
              toggleModal();
              setAnimateModal(true);
            }}
          >
            Download App
          </h4>
        </div>
        <div className="navPhoneBar" ref={sideBarRef}>
          <div className="barContainer" onClick={toggleSideBar}>
            {[1, 2, 3].map((item, index) => {
              return <span key={index.toString()}></span>;
            })}
          </div>
        </div>
        <div className="sideNavContainer" ref={sideBarRef}>
          <nav>
            <ul>
              <li className="rightShiftContainer">
                <img
                  src={rightShiftIcon}
                  alt="icon"
                  onClick={toggleSideBar}
                  style={{ cursor: "pointer" }}
                />
              </li>
              {navData.map((item, index) => {
                return (
                  <li key={index.toString()}>
                    <NavLink
                      className="sideBarItems"
                      to={item.path}
                      style={({ isActive }) => ({
                        color: "#FFFFFF",
                        textDecoration: "none",
                        borderBottom: isActive
                          ? "0.25rem solid #BB68E7"
                          : "none",
                        fontWeight: "800",
                        fontFamily: "Avenir Regular",

                        cursor: "pointer",
                        letterSpacing: "1px",
                      })}
                      onClick={() => {
                        window.scrollTo(0);
                        toggleSideBar();
                      }}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
      <div
        className="dummySpace"
        style={{
          display: !isSticky ? "block" : "none",
        }}
      ></div>
    </>
  );
}

export default Navbar;
