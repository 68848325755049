import React from 'react';
import customer1 from "../../assets/Images/Store/customer1.png";
import customer2 from "../../assets/Images/Store/customer2.png";
import customer3 from "../../assets/Images/Store/customer3.png";
import customer4 from "../../assets/Images/Store/customer4.png";
import customer5 from "../../assets/Images/Store/customer5.png";
import customer6 from "../../assets/Images/Store/customer6.png";
import customer7 from "../../assets/Images/Store/customer7.png";
import customer8 from "../../assets/Images/Store/customer8.png";
import mobileCustomer1 from "../../assets/Images/Store/mobileCustomer1.png";
import mobileCustomer2 from "../../assets/Images/Store/mobileCustomer2.png";
import mobileCustomer3 from "../../assets/Images/Store/mobileCustomer3.png";
import mobileCustomer4 from "../../assets/Images/Store/mobileCustomer4.png";

const windowDimensions = window.innerWidth;

const CustomersReview = () => {
    return (
        <div>
            <div className="customer-detail">
                <h1 className="customer-title">Happy & Fit Customers</h1>
                {windowDimensions > 480 ? (
                    <div className="customer-container">
                        <div className="customer-image-container">
                            <div>
                                <img
                                    src={customer1}
                                    className="customer-img"
                                    alt="customer"
                                />
                                <img
                                    src={customer2}
                                    className="customer-img"
                                    alt="customer"
                                />
                            </div>
                            <div>
                                <img
                                    src={customer3}
                                    className="customer-img"
                                    alt="customer"
                                />
                                <img
                                    src={customer4}
                                    className="customer-img"
                                    alt="customer"
                                />
                            </div>
                        </div>
                        <div className="customer-count">
                            <h1>100000+ Fit Customers</h1>
                        </div>
                        <div className="customer-image-container">
                            <div>
                                <img
                                    src={customer5}
                                    className="customer-img"
                                    alt="customer"
                                />
                                <img
                                    src={customer6}
                                    className="customer-img"
                                    alt="customer"
                                />
                            </div>
                            <div>
                                <img
                                    src={customer7}
                                    className="customer-img"
                                    alt="customer"
                                />
                                <img
                                    src={customer8}
                                    className="customer-img"
                                    alt="customer"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mobile-image-container">
                        <div>
                            <img
                                src={mobileCustomer1}
                                className="mobile-customer-img"
                                alt="customer"
                            />
                            <img
                                src={mobileCustomer2}
                                className="mobile-customer-img"
                                alt="customer"
                            />
                        </div>
                        <div className="mobile-customer-count">
                            <h1>100000+ Customers</h1>
                        </div>
                        <div>
                            <img
                                src={mobileCustomer3}
                                className="mobile-customer-img"
                                alt="customer"
                            />
                            <img
                                src={mobileCustomer4}
                                className="mobile-customer-img"
                                alt="customer"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CustomersReview;