import React from 'react';
import sipperImg from '../../assets/Images/HomePage/shaker.png';
import bottlePriceTag from '../../assets/Images/HomePage/bottlePrice.png';
import backgroundPattern from '../../assets/Images/HomePage/backgroundPatternGG.png';
import cancelIcon from '../../assets/Images/HomePage/cancelIcon.svg';
import checkBox from '../../assets/Images/HomePage/checkbox.png';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    position: 'relative',
    overflowY: 'hidden',
  },
};

const customMobileStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '90%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    position: 'relative',
    overflowY: 'hidden',
  },
};

const windowDimension = window.innerWidth;

const namePattern = /^[A-Za-z ]+$/;
const phonePattern = /^[0-9]*$/;
const pinCodePattern = /^[1-9][0-9]{5}$/;

const SipperCard = () => {

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = React.useState(false);

  const [successModal, setSuccessModal] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  let subtitle;

  const closeSuccessModal = () => {
    setSuccessModal(false);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (event, tag) => {
    const { name, value } = event.target;
    setErrorMsg("");
    if (tag === "phoneNumber") {
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (!formData.firstName) {
      setErrorMsg("Name is mandatory");
      return;
    } if (!namePattern.test(formData.firstName)) {
      setErrorMsg("Name can only contain letters");
      return;
    } else if (!formData.phoneNumber) {
      setErrorMsg("Phone Number is mandatory");
      return;
    } else if (formData.phoneNumber.length !== 10 || !phonePattern.test(formData.phoneNumber)) {
      setErrorMsg("Phone Number invalid!")
      return;
    } else if (!formData.address && !formData.address2) {
      setErrorMsg("Address is mandatory")
      return;
    } else if (!formData.city) {
      setErrorMsg("City Name is mandatory")
      return;
    } else if (!formData.pincode || !pinCodePattern.test(formData.pincode)) {
      setErrorMsg("Pincode is invalid!")
      return;
    }

    try {
      const response = await fetch(
        "https://gymgoer-staging-9etv.onrender.com/api/v1/ecommerce/userinterest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData, productName: 'sipper' }),
        }
      );
      setIsOpen(s => !s);
      if (response.status === 200) {
        setSuccessModal(true);
        setTimeout(() => {
          setSuccessModal(false)
        }, 5000);
      } else {
        console.log("failed", response)
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='proteinCardWrapper'>
      <img src={backgroundPattern} alt='backgroundPattern' className='cardBackground' />
      <img src={sipperImg} alt='protein jar' className='proteinJarImg' />
      <img src={bottlePriceTag} alt='price tag' className='priceTagImg' />
      <div className='topCardDetails'>
        <h1>Grab the Protein Shaker</h1>
        <p>The best shaker you can buy!</p>
        <div
          className='orderButton'
          onClick={() => {
            setIsOpen(s => !s)
          }}
        >
          <h4>Order now</h4>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={windowDimension > 600 ? customStyles : customMobileStyles}
        contentLabel="Address Modal"
      >
        <div className='detailsForm'>
          <div className='blueHalfCircle' />
          <div className='header'>
            <h1>Enter your details</h1>
            <img onClick={closeModal} src={cancelIcon} alt="cancel" />
          </div>
          <form>
            <input
              onChange={handleChange}
              value={formData.firstName}
              type="text"
              name="firstName"
              placeholder="Name"
              maxLength={60}
            />
            <input
              onChange={(e) => {
                handleChange(e, "phoneNumber");
              }}
              value={formData.phoneNumber}
              type="text"
              name="phoneNumber"
              placeholder="Phone Number"
            />
            <input
              onChange={handleChange}
              value={formData.address1}
              type="text"
              name="address"
              placeholder="Address Line 1"
              maxLength={100}
            />
            <input
              onChange={handleChange}
              value={formData.address2}
              type="text"
              name="address2"
              placeholder="Address Line 2"
              maxLength={100}
            />
            <div className='formSplitter'>
              <input
                onChange={handleChange}
                value={formData.city}
                type="text"
                name="city"
                placeholder="City"
                maxLength={20}
              />
              <input
                onChange={handleChange}
                value={formData.pincode}
                type="number"
                name="pincode"
                placeholder="Pincode"
                maxLength={6}
              />
            </div>
            {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
            <button className='submitButton' type="submit" onClick={submitForm}>Submit</button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={successModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeSuccessModal}
        style={windowDimension > 600 ? customStyles : customMobileStyles}
        contentLabel="Success Modal"
      >
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', padding: '50px 0' }}>
          <img src={checkBox} alt='success image' />
          <h2>Your order is placed successfully</h2>
          <p>Our onground team will connect with you for further assistance</p>
        </div>
      </Modal>
    </div >
  )
}

export default SipperCard;

