import React, { useContext } from "react";
import cancelIcon from "../../../assets/Images/HomePage/cancelIcon.svg";
import { modalContext } from "../../LayoutPage";
import "./index.css";
import iosQr from "../../../assets/Images/HomePage/appleQr.png";
import androidQr from "../../../assets/Images/HomePage/androidQr.jpeg";

const QrModal = () => {
  const { toggleModal, animateModal } = useContext(modalContext);
  const scanData = [
    { text: "Scan here to download Android app", icon: androidQr },
    { text: "Scan here to download iOS app", icon: iosQr },
  ];
  return (
    <div
      className={
        animateModal ? "modalInnerContainer active" : "modalInnerContainer"
      }
    >
      <div
        className="cancelContainer"
        onClick={() => {
          toggleModal();
        }}
      >
        <img src={cancelIcon} alt="cancel" />
      </div>
      <h2>Download It Now</h2>
      <div className="qrContent">
        {scanData.map((item, index) => {
          return (
            <div className="qrCard" key={index.toString()}>
              <div>
                <img src={item.icon} alt="QR code" />
              </div>
              <p>{item.text}</p>
            </div>
          );
        })}
      </div>
      <div className="halfCircle"></div>
    </div>
  );
};

export default QrModal;
