import gymgoerLogo from "../../assets/Images/HomePage/GGLogo.png";
import appleStoreImage from "../../assets/Images/Store/appStore.png";
import googleStoreImage from "../../assets/Images/Store/playStore.png";
import facebookIcon from "../../assets/Images/Store/facebookLogo.png";
import instagramIcon from "../../assets/Images/Store/instagramLogo.png";
import useAnalyticsEventTracker from "../useAnalyticsEventTracker";
import linkedInLogo from "../../assets/Images/Store/linkedinLogo.png";
import { NavLink } from "react-router-dom";
import "./index.css";

const NewFooter = () => {
  const otherPage = [
    { title: "Home", path: "/" },
    { title: "About Us", path: "/aboutUs" },
    // { title: "Blog", path: "/blogs" },
    { title: "Store", path: "/store" },
    { title: "Partner With Us", path: "/partnerWithUs" },
  ];

  const gaPlayStoreTracker = useAnalyticsEventTracker("playstore button");
  const gaAppleStoreTracker = useAnalyticsEventTracker("appletore button");
  const storeData = [
    {
      title: "",
      alternate: "storeImage",
      source: appleStoreImage,
      link: "https://apps.apple.com/in/app/gymgoer/id6451078977",
      tracker: gaAppleStoreTracker,
    },
    {
      title: "",
      alternate: "storeImage",
      source: googleStoreImage,
      link: "https://play.google.com/store/apps/details?id=com.gymgoer",
      tracker: gaPlayStoreTracker,
    },
  ];

  const socialMediaData = [
    {
      title: "Facebook",
      icon: facebookIcon,
      alternate: "socialIcon",
      link: "https://www.facebook.com/profile.php?id=100088500812604",
    },
    {
      title: "Instagram",
      icon: instagramIcon,
      alternate: "socialIcon",
      link: "https://www.instagram.com/gymgoer.fit/",
    },
    {
      title: "LinkedIn",
      icon: linkedInLogo,
      alternate: "socialIcon",
      link: "https://www.linkedin.com/company/gymgoer/?viewAsMember=true",
    },
  ];

  return (
    <div className="footer-store-container">
      <div className="footerLogoContainer">
        <NavLink
          to="/"
          onClick={() => {
            window.scrollTo(0);
          }}
        >
          <img src={gymgoerLogo} alt="mainLogo" />
        </NavLink>
      </div>
      <div className="footerOption">
        <ul>
          {otherPage.map((item, index) => {
            return (
              <li key={index.toString()}>
                <a
                  href={item.path}
                  onClick={() => {
                    window.scrollTo(0);
                  }}
                >
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="app-download">
        <div className="social-logo-container">
          {socialMediaData.map((data, index) => {
            return (
              <a href={data.link} key={index.toString()} target="blank">
                <img
                  src={data.icon}
                  className="social-logo"
                  alt={data.alternate}
                />
              </a>
            );
          })}
        </div>
        <h3 className="discover-app-text">Discover our app</h3>
        <div style={{ display: "flex", gap: 8 }}>
          {storeData.map((item, index) => (
            <a
              href={item.link}
              key={index.toString()}
              onClick={() => item.tracker("store link")}
              target="blank"
            >
              <img
                src={item.source}
                className="download-logo"
                alt={item.alternate}
              />
            </a>
          ))}
        </div>
      </div>
      <div className="reserve-right-container">
        <NavLink
          to="/privacypolicy"
          onClick={() => {
            window.scrollTo(0);
          }}
        >
          <p>
            Security Policy |Privacy Policy | Returns & refund Policy</p>
        </NavLink>
        <p className="reserve-right-text">
          All rights reserved@fitclubgymgoer.fit
        </p>
      </div>
    </div>
  );
};

export default NewFooter;
