import React from 'react';
import sugarfree from '../../assets/Images/HomePage/sugarfree.png';

import './index.css';

const IngredientsNew = () => {
  return (
    <div className='ingredientsContainer' >
      <div className='headingGradient'>
        <h1>Flavoured with Rich Chocolate</h1>
      </div>
      <div className="grid-container">
        <div className="grid-item protein">
          <h1>25g</h1>
          <p>Protein</p>
        </div>
        <div className="grid-item eaas">
          <h1>11.7g</h1>
          <p>EAAs*</p>
        </div>
        <div className="grid-item bcaas">
          <h1>5.5g</h1>
          <p>BCAAs*</p>
        </div>
        <div className="grid-item glutamic-acid">
          <h1>4.3g</h1>
          <p>Glutamic Acid</p>
        </div>
      </div>
      <div className='sugarBottomRightCard'>
        <div className='sugarIconWrapper'>
          <img
            src={sugarfree}
            alt="sugar free"
            className="medium-icon"
          />
        </div>
        <div>
          <h1>ZERO</h1>
          <h2>Added Sugar</h2>
        </div>
      </div>

    </div>
  );
}

export default IngredientsNew;