import { useEffect, useRef } from "react";
import "./index.css";
import "./index.css";
import videoFile from "../../assets/Images/Store/video.mp4";

import CustomersReview from "./CustomerReview";
import ProteinPurity from "./ProteinPurity";
import IngredientsNew from "./IngredientsNew";
import HowToUseNew from "./HowToUseNew";
import ProductCardWrapper from "./ProductCardWrapper";


const Store = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const pointer = videoRef.current;

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          pointer.play();
        } else {
          pointer.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(pointer);

    return () => {
      if (pointer) {
        observer.unobserve(pointer);
      }
    };
  }, []);

  return (
    <div style={{ paddingTop: 60, overflow: "hidden" }}>
      {/* <HomePageBennerSection /> */}
      <ProductCardWrapper />

      <div className="banner-BG"><h1> "Craft Your Dream Physique with Every Sip"</h1></div>
      <div className="usage-BG">
        <div className="video-container">
          <video ref={videoRef} className="video-box" autoPlay controls muted>
            <source src={videoFile} type="video/mp4" />
          </video>
        </div>
      </div >
      <IngredientsNew />
      <ProteinPurity />
      {/* <Ingredients /> */}
      <HowToUseNew />

      {/* <HowToUse /> */}
      <CustomersReview />
    </div >
  );
};

export default Store;
