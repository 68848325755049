import "./index.css";

const Policy = () => {
  const headerContent = [
    "Gym Goer Pvt Ltd built the Gym Goer app as a Commercial app. This SERVICE is provided by Gym Goer Pvt Ltd and is intended for use as is.",
    "This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.",
    "If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.",
    "The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Gym Goer unless otherwise defined in this Privacy Policy.",
  ];
  const informationData = [
    {
      heading: "Information Collection and Use",
      data: [
        "For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Contact. The information that we request will be retained by us and used as described in this privacy policy.",
        "The app does use third-party services that may collect information used to identify you.",
        "Link to the privacy policy of third-party service providers used by the app",
      ],
      listItem: [
        {
          title: "Google Play Services",
          link: "https://www.google.com/policies/privacy/",
        },
        {
          title: "Google Analytics for Firebase",
          link: "https://firebase.google.com/policies/analytics",
        },
        {
          title: "Firebase Crashlytics",
          link: "https://firebase.google.com/support/privacy/",
        },
      ],
    },
  ];
  const logData = [
    {
      heading: "Log Data",
      data: [
        "We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.",
      ],
    },
  ];
  const cookiesData = [
    {
      heading: "Cookies",
      data: [
        "Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.",
        "This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.",
      ],
    },
  ];
  const serviceProvider = [
    {
      heading: "Service Providers",
      data: [
        "We may employ third-party companies and individuals due to the following reasons:",
      ],
      footer: [
        "We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
      ],
      listItem: [
        "To facilitate our Service;",
        "To provide the Service on our behalf;",
        "To perform Service-related services; or",
        "To assist us in analyzing how our Service is used.",
      ],
    },
  ];
  const securityData = [
    {
      heading: "Security",
      data: [
        "We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.",
      ],
    },
  ];
  const otherSiteData = [
    {
      heading: "Links to Other Sites",
      data: [
        "This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.",
      ],
    },
  ];
  const privacyData = [
    {
      heading: "Children’s Privacy",
      data: [
        "These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.",
      ],
    },
  ];
  const changesPrivacyData = [
    {
      heading: "Changes to This Privacy Policy",
      data: [
        "We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.",
        "This policy is effective as of 2022-09-01",
      ],
    },
  ];
  const contactData = [
    {
      heading: "Contact Us",
      data: [
        "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at support@gymgoer.fit.",
      ],
    },
  ];

  const privacyPolicyContent = [
    {
      heading: "Do we share any personal customer information with anyone?",
      content: "We share your personal information only with a few of our trusted partners including the payment gateway, order fulfilment and tracking partners. Sharing of this data is necessary in order to fulfil your order. Be assured that we will do our best to always protect your personal information."
    },
    {
      heading: "What do we do with your information?",
      content: `When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address, phone number, email address, billing, order history, feedback, about your visits to and use of gymgoer.fit (including your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation etc.)
      Email / SMS(if applicable): We may send you emails or SMS about your order, our store, new products, and other updates.`
    }, { heading: 'CONSENT' },
    {
      heading: "How do you get my consent?",
      content: `If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at support@gymgoer.fit`
    },
    {
      heading: "DISCLOSURE",
      content: "We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service."
    },
    {
      heading: "PAYMENT & SECURITY",
      content: "Gymgoer.fit is committed to provide you with the most secure experience. For more details, please see our Security Policy"
    },

    {
      heading: "THIRD-PARTY SERVICES",
      content: `In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.
      However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies with respect to the information we are required to provide to them for your purchase-related transactions.
      For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.
      In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
      As an example, if you are located in India and your transaction is processed by a payment gateway located in India, then your personal information used in completing that transaction may be subject to disclosure under the Indian legislation. Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.`
    },
    {
      heading: "Links:",
      content: "The Website may be linked to the website of third parties, affiliates, and business partners. We have no control over, and not liable or responsible for content, accuracy, validity, reliability, quality of such websites or made available by/through our Website. The inclusion of any link on the Website does not imply that We endorse the linked site. The user may use the links and these services at User’s own risk."
    },
    {
      heading: "INFORMATION SECURITY",
      content: `To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. Any information that we collect and store that is personally identifiable is protected using appropriate means, and Gymgoer is not responsible for any unauthorized access or use by third parties.
      If you provide us with your credit card, net banking, UPI, mobile wallets or any other online payment information, the information is encrypted with the payment gateway providers only and not by us.
      `
    },
    {
      heading: "AGE OF CONSENT",
      content: `By using this site, you represent that you are at least the age of majority, or that you are the age of majority you have given us your consent to allow any of your minor dependents to use this site.`
    },

    {
      heading: "CHANGES TO THIS PRIVACY POLICY",
      content: `We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
      If our store is acquired or merged with another company in future, your information may be transferred to the new owners so that we may continue to sell products to you.`
    },
    {
      heading: "QUESTIONS AND CONTACT INFORMATION",
      content: `If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information, contact our Privacy Compliance Officer at support@gymgoer.fit`
    },

  ]

  const securityPolicy = [
    {
      heading: "Is it safe to use my credit/debit card on GymGoer?",
      content: `Gymgoer has partnered with payment gateway providers that securely process your payment and have a high level of security measures implemented in their systems.
      Please note that Gymgoer.fit does not store, process or have any access to your card details. It may be saved with our payment gateway partners based upon your consent given to them.`
    },
    {
      heading: "Does Gymgoer store my credit or debit card information?",
      content: `Please note that Gymgoer.fit does not store, process or have any access to your card details. It may be saved with our payment gateway partners based upon your consent given to them.`
    },
    {
      heading: "What is a Payment Gateway?",
      content: `Payment Gateways are online payment processors who accept and validate Internet payments from the end customers in real-time. They provide a secure link between our website, various issuing institutions, acquiring Banks and the payment gateway providers.`
    },
    {
      heading: "What are the payment methods that we accept?",
      content: "We accept Debit Cards, Credit Card, Net banking, UPI, Payment Wallets. Card Network- Visa, MasterCard, AMEX, Rupay for online payments other than pay on delivery option."
    },
    {
      heading: "What security measures do our partner payment gateways provide for processing digital payments?",
      content: `We use India’s most popular and trusted payment gateways. They are PCI compliance and ISO certified. About PCI compliance: The Payment Card Industry Data Security Standard is a set of security standards designed to ensure that ALL companies that accept, process, store, or transmit credit card information maintain a secure environment. PCI audit rules are governed by the council which is run by VISA & Master Card.`

    },

    {
      heading: "What payment gateways has Gymgoer partnered with?",
      content: `Gymgoer.fit currently uses Phonepe and Razorpay as the payment gateway partners. For more details on their contact details, privacy policy, security details or terms and conditions, we suggest directly going through their website.`
    },
    {
      heading: "Privacy Policy",
      content: "Gymgoer.fit respects your privacy and is committed to protecting it. For more details, please see our Privacy Policy"
    },
    {
      heading: "Contact Us",
      content: `Couldn’t find the information you need? Please Contact Us at support@gymgoer.fit`
    },

  ]


  const returnPolicy = [
    {
      heading: "Is my product eligible for Return?      ",
      content: `Products are not eligible for return. In cases where the issue is: Wrong Item Delivered, Item In box Damaged (but Shipping box is ok), Missing Item in Order, we would require customers to contact us with images/videos of the packaging box and the product within 24 hours of the order delivery date. We recommend you to capture an unboxing video so that it can act as an evidence to resolve the above claims.`
    },
    {
      heading: "What happens if I receive a fake product?",
      content: `Please be informed that at Gymgoer.fit, we do not have any 3rd party sellers. This means that 100% of the products listed on our website are fulfilled directly by us in our own warehouse only. Due to this, We/You do not have to rely on anyone else to send an authentic product. Since all the products are fulfilled & delivered by Gymgoer.fit only, and we are the official, authorized and certified distributor/seller of all the brands we sell, you cannot receive a fake product. If you need help with authenticity verification, it can be done by directly contacting the brand customer care and sharing product images/batch numbers.`
    },
    {
      heading: "What happens if the product is close to expiry?",
      content: `We usually keep the latest batches of all stocks here as we receive new stocks on a monthly basis from manufacturers, so you should not worry about receiving a near-expiry product. For products that have more than 18 months of shelf life, 4 months or less remaining is considered as 'near expiry', and for products that have 12 months to 18 months of shelf life, 2 months or less remaining is considered as 'near expiry'.`
    },
    {
      heading: "How do I initiate a return?",
      content: "To initiate a return, we request you to email us using the contact us form on the help center page with details of the issue and relevant images/videos to support the case."
    },
    {
      heading: "What is the Return Process?",
      content: `Once your return request is approved, a courier partner will be assigned to pick up your product, and you will receive further instructions on how to proceed. Please ensure that the product is securely packaged to prevent any damage during the return shipping process.`
    },

    {
      heading: "How will the refund be processed?",
      content: `Upon receiving and inspecting the returned product, we will process your refund within 7 business days. Refunds will be issued to the original payment method used for the purchase. For Pay on Delivery orders, we will ask you for your bank details and transfer the money digitally. Please allow 7-10 business days for the refund to reflect in your account.`
    },
    {
      heading: "Where can I view the status of Return / Refund?",
      content: `Locate the item from My Orders. You can view the Return / Refund status.
       `
    },
    {
      heading: "What should I do if the shipping box received is damaged?",
      content: `In a rare case where you find the shipping box damaged/tampered or without the GYMGOER branded tape, please reject it at the time of delivery (including prepaid orders) and contact us immediately & we will resolve the case or issue a refund as applicable.`
    },

    {
      heading: "What happens if I get a notification that my order was marked as delivered, but I never received it?",
      content: `In an extremely rare and unlikely scenario like this, we require you to inform us within 2 days of receiving the notification of the order being complete so that we can take necessary action with the courier/delivery person and resolve the case.

       `
    },
    {
      heading: "How long does it take to process refunds?",
      content: `We issue refunds within 3 business days of approving the refund. Once initiated on our end, your bank can take up to 7 business days to process and reflect the refund.
      `
    },
  ]

  return (
    <div className="policyContainer">
      <div className="policyHeader">
        <h2>Gym Goer Privacy Policy</h2>
      </div>
      {headerContent.map((item) => {
        return <p className="policyParagraph">{item}</p>;
      }
      )}

      {privacyPolicyContent.map((item) => {
        return (

          <div>
            {item.heading && <h3 className="sectionHeading">{item.heading}</h3>}
            {item.content && <div className="policyParagraph">{item.content}</div>}
          </div>
        )
      })}
      <div className="informationSection">
        {informationData.map((item) => {
          return (
            <>
              <h3 className="sectionHeading">{item.heading}</h3>
              {item.data.map((entry) => {
                return <p className="policyParagraph">{entry}</p>;
              })}
            </>
          );
        })}
        <ul>
          {informationData.map((item) => {
            return (
              <>
                {item.listItem.map((entry) => {
                  return (
                    <li>
                      <a href={entry.link} className="policyLinkText">
                        {entry.title}
                      </a>
                    </li>
                  );
                })}
              </>
            );
          })}
        </ul>
      </div>
      {logData.map((item) => {
        return (

          <>
            <h3 className="sectionHeading">{item.heading}</h3>
            {item.data.map((entry) => {
              return <p className="policyParagraph">{entry}</p>;
            })}
          </>
        );

      })}
      {cookiesData.map((item) => {
        return (

          <>
            <h3 className="sectionHeading">{item.heading}</h3>
            {item.data.map((entry) => {
              return <p className="policyParagraph">{entry}</p>;
            })}
          </>
        );

      })}
      <div className="serviceSection">
        {serviceProvider.map((item) => {
          return (
            <>
              <h3 className="sectionHeading">{item.heading}</h3>
              {item.data.map((entry) => {
                return <p className="policyParagraph">{entry}</p>;
              })}
            </>
          );
        })}
        <ul>
          {serviceProvider.map((item) => {
            return (
              <>
                {item.listItem.map((entry) => {
                  return <li>{entry}</li>;
                })}
              </>
            );
          })}
        </ul>
        {serviceProvider.map((item) => {
          return (

            <>
              {item.footer.map((entry) => {
                return <p className="policyParagraph">{entry}</p>;
              })}
            </>
          );

        })}
      </div>
      {securityData.map((item) => {
        return (

          <>
            <h3 className="sectionHeading">{item.heading}</h3>
            {item.data.map((entry) => {
              return <p className="policyParagraph">{entry}</p>;
            })}
          </>
        );

      })}
      {otherSiteData.map((item) => {
        return (

          <>
            <h3 className="sectionHeading">{item.heading}</h3>
            {item.data.map((entry) => {
              return <p className="policyParagraph">{entry}</p>;
            })}
          </>
        );

      })}
      {privacyData.map((item) => {
        return (

          <>
            <h3 className="sectionHeading">{item.heading}</h3>
            {item.data.map((entry) => {
              return <p className="policyParagraph">{entry}</p>;
            })}
          </>
        );

      })}
      {changesPrivacyData.map((item) => {
        return (

          <>
            <h3 className="sectionHeading">{item.heading}</h3>
            {item.data.map((entry) => {
              return <p className="policyParagraph">{entry}</p>;
            })}
          </>
        );

      })}
      <div className="policyContact">
        {contactData.map((item) => {
          return (
            <>
              <h3 className="sectionHeading">{item.heading}</h3>
              {item.data.map((entry) => {
                return <p className="policyParagraph">{entry}</p>;
              })}
            </>
          );
        })}
        <p className="policyParagraph">
          This privacy policy page was created at{" "}
          <a
            className="policyLinkText"
            href="https://www.privacypolicytemplate.net/"
          >
            privacypolicytemplate.net
          </a>{" "}
          and modified/generated by{" "}
          <a
            className=" policyLinkTex t"
            href="https://app-privacy-policy-generator.nisrulz.com/"
          >
            App Privacy Policy Generator
          </a>
        </p>
      </div>

      <div style={{ margin: '100px 0 30px 0' }} className="policyHeader">
        <h2>GymGoer's Security Policy</h2>
      </div>
      {securityPolicy.map((item) => {
        return (

          <div>
            {item.heading && <h3 className="sectionHeading">{item.heading}</h3>}
            {item.content && <div className="policyParagraph">{item.content}</div>}
          </div>
        )
      })}
      <div style={{ margin: '100px 0 30px 0' }} className="policyHeader">
        <h2>Return, Refund & Shipping Policy</h2>
      </div>
      {returnPolicy.map((item) => {
        return (

          <div>
            {item.heading && <h3 className="sectionHeading">{item.heading}</h3>}
            {item.content && <div className="policyParagraph">{item.content}</div>}
          </div>
        )
      })}
    </div>
  );
};

export default Policy;
