import React from "react";
import "./index.css";
import appleIcon from "../../../assets/Images/HomePage/appleSvg.svg";
import googlePlayIcon from "../../../assets/Images/HomePage/googlePlaySvg.svg";
import screenCenter from "../../../assets/Images/HomePage/phoneScreenCenter.png";
import screenLeft from "../../../assets/Images/HomePage/phoneScreenLeft.png";
import screenRight from "../../../assets/Images/HomePage/phoneScreenRight.png";
import cancelIcon from "../../../assets/Images/HomePage/cancelIcon.svg";

const QrMobileModal = () => {
  const playStoreData = [
    {
      icon: googlePlayIcon,
      alter: "googleStore",
      link: "https://play.google.com/store/apps/details?id=com.gymgoer",
    },
    {
      icon: appleIcon,
      alter: "appleStore",
      link: "https://apps.apple.com/in/app/gymgoer/id6451078977",
    },
  ];
  const phoneScreenData = [
    { icon: screenCenter, name: "centerScreen", alter: "phoneScreen" },
    { icon: screenLeft, name: "leftScreen", alter: "phoneScreen" },
    { icon: screenRight, name: "rightScreen", alter: "phoneScreen" },
  ];
  return (
    <div className="qrMobileContainer">
      <div className="cancelContainer">
        <img src={cancelIcon} alt="cancel" />
      </div>
      <h1>Download The App</h1>
      <div className="storeImageContainer">
        {playStoreData.map((item, index) => {
          return (
            <a href={item.link} target="blank" key={index.toString()}>
              <img src={item.icon} alt={item.alter} />
            </a>
          );
        })}
      </div>
      <div className="qrMobileContent">
        <div className="phoneScreenContainer">
          {phoneScreenData.map((item, index) => {
            return (
              <img
                alt={item.alter}
                src={item.icon}
                className={item.name}
                key={index.toString()}
              />
            );
          })}
        </div>
      </div>
      <div className="backgroundSphere"></div>
    </div>
  );
};

export default QrMobileModal;
