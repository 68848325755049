import React from 'react';
import chillerWater from '../../assets/Images/HomePage/Chilledwater.png';
import scoopImg from '../../assets/Images/HomePage/HeapingScoop.png';

import './index.css';
const windowDimensions = window.innerWidth;
const HowToUseNew = () => {
  return (
    <div className='consumption-wrapper'>
      {windowDimensions > 480 ? (
        <h2>
          How to use <span style={{ color: "#A2734A" }}>GG Fitness</span> Whey
          Protein?
        </h2>
      ) : (
        <h2>How to use?</h2>
      )}
      <div className="consumtion-container">
        <div className="consumtion-box">
          <div>
            <img src={scoopImg} alt='scoopImg' />
            <img src={chillerWater} alt='scoopImg' />
          </div>
          <h3>
            Start consuming ½ Scoop (17.5 gl of GYMGOER® Whey Protein in 100mL
            of chilled water for the initial 3 days in a row.
          </h3>
        </div>
        <div className="consumtion-box">
          <div>
            <img src={scoopImg} alt='scoopImg' />
            <img src={chillerWater} alt='scoopImg' />
          </div>
          <h3>
            From 4th day, consume 1 Heaping Scoop (35g) of GYMGOER® Whey
            Protein in 200mL of chilled water.
          </h3>
        </div>
      </div>
    </div>
  )
}

export default HowToUseNew;