import React from 'react';
import "./ProductCards.css";
import ProteinCard from './ProteinCard';
import SipperCard from './SipperCard';

const ProductCardWrapper = () => {
  return (
    <div className='productCardWrapper'>
      <ProteinCard />
      <SipperCard />
    </div>
  )
}

export default ProductCardWrapper;