import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ALL_CATEGORIES, BLOG_DATA } from "../constant";
import TopArticle from "./TopArticle.jsx";
import "./styles.scss";

const BlogHeader = () => {
  const [selectedCategory, setSelectedCategory] = useState(ALL_CATEGORIES[2]);
  const [categoryData, setCategoryData] = useState(
    BLOG_DATA[ALL_CATEGORIES[0]]
  );
  const navigate = useNavigate();

  const changeCategory = (newCategory) => {
    setSelectedCategory(newCategory);
    setCategoryData(BLOG_DATA[newCategory]);
  };

  const goToAnArticle = (articleURL) => {
    navigate(articleURL);
  };

  const backgroundColor = (category) => {
    if (window.innerWidth < 699 && category === selectedCategory) {
      return "linear-gradient(180deg, #FF7A00, #A92900)";
    } else if (window.innerWidth > 699) {
      return "none";
    } else {
      return "#D9D9D9";
    }
  };

  return (
    <div className="blogContainer">
      <div className="blogHeaderContainer">
        {ALL_CATEGORIES.map((category) => {
          return (
            <ul
              style={{
                color:
                  category === selectedCategory && window.innerWidth > 699
                    ? "#3B005A"
                    : "white",
                background: backgroundColor(category),
              }}
              onClick={() => changeCategory(category)}
            >
              {category}
            </ul>
          );
        })}
      </div>
      <div id="blogCoverImg">
        <img
          src={categoryData.coverImage}
          alt={`cover for ${selectedCategory} category`}
        />
        <p>{selectedCategory}</p>
      </div>

      <h1 className="blogSubheading">Top Articles</h1>
      <div className="topSingleArticlesWrapper">
        <TopArticle
          articleInfo={categoryData.topArticles[0]}
          singleLiner={false}
          goToAnArticle={goToAnArticle}
          topSingleLiner={true}
        />
      </div>
      <div className="topArticlesWrapper">
        {categoryData.topArticles.map((article, index) => (
          <TopArticle
            key={index}
            articleInfo={article}
            singleLiner={false}
            goToAnArticle={goToAnArticle}
            topSingleLiner={false}
          />
        ))}
      </div>

      <h1 className="blogSubheading">More On This Topic</h1>
      <div className="topArticlesWrapper">
        {categoryData.moreArticles.map((article, index) => (
          <TopArticle
            key={index.toString()}
            articleInfo={article}
            singleLiner={true}
            goToAnArticle={goToAnArticle}
            topSingleLiner={false}
          />
        ))}
      </div>
    </div>
  );
};

export default BlogHeader;
