import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import HomePage from "./pages/HomePage/index.jsx";
import Policy from "./pages/PolicyPage";
import Layout from "./pages/LayoutPage";
import NoPage from "./pages/Nopage/index.jsx";
import OwnerShare from "./pages/OwnerSharePage";
import ReactGA from "react-ga";
import Store from "./pages/Store";
import PartnerWithUs from "./pages/PartnerWithUs/index";
import AboutUs from "./pages/AboutPage/index.jsx";
import Blogs from "./pages/blog/index.jsx";
import ArticleFull from "./pages/blog/articles/ArticleFull.jsx";

const TRACKING_ID = "G-F3G1NGDWF3";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* <Route index element={<HomePage />} /> */}
          <Route index element={<Store />} />
          <Route path="aboutUs" index element={<AboutUs />} />
          <Route path="store" index element={<Store />} />
          <Route path="partnerWithUs" element={<PartnerWithUs />} />
          <Route path="blogs" element={<Blogs />} />
        </Route>
        <Route exact path="/ownershare" element={<OwnerShare />} />
        <Route exact path="/privacypolicy" element={<Policy />} />
        <Route path="/blogs/:category" element={<Blogs />} />
        <Route path="/blogs/:category/:blogId" element={<ArticleFull />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  );
};

export default App;
