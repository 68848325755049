import React from 'react';
import pattern from '../../assets/Images/HomePage/backgroundPatternGG.png';

const PURITY_DETAILS = [
  [{
    heading: 'Pureity',
    body: `GymGoer Whey protein defines the meaning of purity by following
    the zero adulteration policy. Every scoop of whey protein provides
    you the best in class experience with no additives. Our target is
    to improve the performance of the consumer in every aspect of
    achieving their goal towards the body building`
  },
  {
    heading: 'Flavour',
    body: `The double chocolate flavour is truly best in class.It will takes
    your taste buds to a world of indulgence.Protein is often very
    dull without flavour, but chocolate flavour adds life to the
    drink, turning your drink into a blissful experience. Try the
    double chocolate flavour and share your delightful experience with
    us!`
  },
  ],
  [{
    heading: 'Protein',
    body: `With one serving per day fulfil your daily dose of protein intake.
    For the best results, mix one serving with water or milk and shake
    for 60-90 seconds and have this high protein shake post workout or
    consume it as anytime snack in your balanced diet.A serving of 34
    g scoop gives you 24g of Protein.`
  },
  {
    heading: 'Digestibility & Absorption',
    body: `The rapid digestion and absorption makes it an ideal choice for
    your post-workout recovery and provides you best in class muscle
    building results. We recommend 1 scoop of Whey which can serve you
    25 grams of Protein post workout for best results. No sugar added
    in the product.For more information read the Nutrition information
    section on the package`
  },

  ]
]
const ProteinPurity = () => {
  return (
    <div className="review-BG">
      <img
        src={pattern}
        alt="bacgkround pattern"
      />
      {PURITY_DETAILS.map(info => {
        const myAns = info.map(details => {
          return (
            <div className='review' key={details.heading}>
              <h1>{details.heading}</h1>
              <p>{details.body}</p>
            </div>
          )
        })
        return (
          <div className="review-container" style={{ marginTop: "4rem" }}>
            {myAns.map(val => val)}
          </div>
        )
      })}
    </div>
  )
}
export default ProteinPurity;
